//Sample application demonstrating how to perform a Spark OAuth2 authentication and Spark API access token request
//Note: this page must be accessed from a web server, Step #3 below may fail if loaded from the file system
import * as $ from 'jquery';
import {startTimer} from './timer';
import {qrcode} from './jquery.qrcode';
import {config} from './config';
import './bootstrap';

var appRedirectUri = config.credentials.redirect_uri;

var appClientId = config.credentials.client_id; 
var appClientSecret = process.env.INT_CLIENT_SECRET;

// Helper function that generates a random alpha/numeric string
var randomString = function(length) {
    var str = "";
    var range = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for(var i = 0; i < length; i++) {
        str += range.charAt(Math.floor(Math.random() * range.length));
    }
    return str;
}

// Helper function that parses a query string into a dictionary object
var parseQueryStr = function( queryString) {
    var params = {}, keyvals, tmp, i, l;
    keyvals = queryString.split("&");  // Split out key/value pairs
    for ( i = 0, l = keyvals.length; i < l; i++ ) {  // Split key/value strings into a dictionary object
        tmp = keyvals[i].split('=');
        params[tmp[0]] = tmp[1];
    }
    return params;
};


// Step #1: Fires when the user clicks the 'Request Auth Code' button
function codeClick () {
	//Build the request URL.  The base URL and next 4 items are typically always the same for Spark web apps
	var requestUrl = 'https://api.ciscospark.com/v1/authorize?' + //Spark OAuth2 base URL
		'response_type=code&' + // Requesting the OAuth2 'Authentication Code' flow
		'scope='+ encodeURIComponent('spark:all') + '&' + // Requested permission, i.e. Spark room info
		// The following items are provided by the developer in the source code/config or generated dynamically at run time
		'state=' + encodeURIComponent(randomString(63)) + '&' +	// Random string for OAuth2 nonce replay protection
		'client_id=' + encodeURIComponent(appClientId) + '&' + // The custom app Client ID
		'redirect_uri=' + encodeURIComponent(appRedirectUri); // The custom app's Redirect URI
  //  console.log(requestUrl);
	window.location = requestUrl; // Redirect the browser to the OAuth2 kickoff URL
};

// Step #2: On page load, check if the 'code=' query param is present
// If so user has already authenticated, and  page has been reloaded via the Redirect URI
window.onload = function(e) {
	//document.getElementById('redirectUri').value=window.location.href.split("?")[0]; // Detect the current page's base URL

	var params = parseQueryStr(window.location.search.substring(1)); // Parse the query string params into a dictionary
	if (params['code']) { // If the query param 'code' exists, then...
  // console.log(params['code']);
  startTimer.start()

  $(function(){
    $('#qrcode').addClass('qrcode-displayed');
    $('div').css("margin-top", "7px");
    $('button').css("visibility", "hidden");
    $('.display-remain-time').css("visibility", "visible");
    $('.circle').css("visibility", "visible");
    $('#qrcode').qrcode(params['code']);
    $('.button-text').html('Scan Code');
    // console.log(qrcode(params['code']));
  });

		//document.getElementById('tokenButton').removeAttribute('hidden'); // Reveal the 'Request Access Token' button
	}
	if (params['error']) { // If the query param 'error' exists, then something went wrong...
		alert('Error requesting auth code: ' + params['error'] + ' / ' + params['error_description']);
	}
}

$(document).ready(function() {
  // codeClick();
  $('#codeButton').click(codeClick);
  $('#qrcode').click(function(){
  //  console.log("mouseleave");
    $('.onboarding-form-logo-section').css({"transform": "scale(1)","padding-top": "0px","transition": "transform .2s"});
    $('.onboarding-form').css({"transform":"scale(1)","margin-top":"17px","transition":"transform .2s"});
    //$('.qrcode_title').css({"transform":"scale(1)","margin-top":"17px","transition":"transform .2s"});
    //$('#qrcode').css({"width":"160px", "height":"160px","margin-top":"0.5rem","margin-bottom":"0.5rem", "margin-lef":"30%", "border":"#049fd9 solid 1px", "transition":"transform .2s"});
    $('#qrcode').css({"transform":"scale(1)"});
    })
    $('#qrcode').dblclick(function(){
      //console.log("mouseenter");
      $('.onboarding-form-logo-section').css({"transform": "scale(0.7)","padding-top": "0px", "margin-top":"10px","transition": "transform .2s"});
      $('.onboarding-form').css({"transform":"scale(0.7)","margin-top":"17px","transition":"transform .2s"});
      $('#qrcode').css({"transform":"scale(1.5)"});
      $('.container').css({"transform":"scale(0.7)", "top":"425px","transition":"transform .2s"});
    })
});
