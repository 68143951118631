

export const startTimer = (function(){
  //circle start
  let progressBar = document.querySelector('.e-c-progress');
  let indicator = document.getElementById('e-indicator');
  let pointer = document.getElementById('e-pointer');
  let qrcodeHolder = document.getElementById('qrcode');
  let circle = document.querySelector('.circle');
  let remainTime = document.querySelector('.display-remain-time');
  let codeBtn = document.getElementById('codeButton');
  let containerCircle = document.getElementById('container-circle');
  let onboarding = document.querySelector('.onboarding-form');


  let length = Math.PI * 2 * 100;
  progressBar.style.strokeDasharray = length;

  function update(value, timePercent) {
  	var offset = - length - length * value / (timePercent);
  	progressBar.style.strokeDashoffset = offset;
  	pointer.style.transform = `rotate(${360 * value / (timePercent)}deg)`;
    if(value == 0){
      qrcodeRefresh();
    }
  };

  function qrcodeRefresh(){
    qrcodeHolder.style.visibility = "hidden";
    qrcodeHolder.style.marginTop = "30px";
    document.querySelector('#qrcode canvas').remove();
    document.querySelector('.onboarding-form-logo-section').style.marginTop = "30px";
    document.querySelector('.button-text').innerHTML = "Request Code";
    qrcodeHolder.classList.remove('qrcode-displayed');
    onboarding.style.marginTop = "90px"
    //qrcodeHolder.classList.add('div-standard');
    remainTime.style.visibility = "hidden";
    containerCircle.style.visibility = "hidden";
  //remainTime.remove();
    circle.style.visibility = "hidden";
    codeBtn.style.visibility = "visible";
  }


  //circle ends
  const displayOutput = document.querySelector('.display-remain-time')
  const pauseBtn = document.getElementById('pause');
  const setterBtns = document.querySelectorAll('button[data-setter]');

  let intervalTimer;
  let timeLeft;
  let wholeTime = 2 * 60; // manage this to set the whole time
  let isPaused = false;
  let isStarted = false;

  update(wholeTime,wholeTime); //refreshes progress bar
  displayTimeLeft(wholeTime);

  function changeWholeTime(seconds){
    if ((wholeTime + seconds) > 0){
      wholeTime += seconds;
      update(wholeTime,wholeTime);
    }
  }

  for (var i = 0; i < setterBtns.length; i++) {
      setterBtns[i].addEventListener("click", function(event) {
          var param = this.dataset.setter;
          switch (param) {
              case 'minutes-plus':
                  changeWholeTime(1 * 60);
                  break;
              case 'minutes-minus':
                  changeWholeTime(-1 * 60);
                  break;
              case 'seconds-plus':
                  changeWholeTime(1);
                  break;
              case 'seconds-minus':
                  changeWholeTime(-1);
                  break;
          }
        displayTimeLeft(wholeTime);
      });
  }

  function timer (seconds){ //counts time, takes seconds
    let remainTime = Date.now() + (seconds * 1000);
    displayTimeLeft(seconds);
    intervalTimer = setInterval(function(){
      timeLeft = Math.round((remainTime - Date.now()) / 1000);
      if(timeLeft < 0){
        clearInterval(intervalTimer);
        isStarted = false;
        setterBtns.forEach(function(btn){
          btn.disabled = false;
          btn.style.opacity = 1;
        });
        displayTimeLeft(wholeTime);
        return ;
      }
      displayTimeLeft(timeLeft);
    }, 1000);
  }

  function pauseTimer(event){
    if(isStarted === false){
      timer(wholeTime);
      isStarted = true;
      this.classList.remove('play');
      this.classList.add('pause');

      setterBtns.forEach(function(btn){
        btn.disabled = true;
        btn.style.opacity = 0.5;
      });

    }else if(isPaused){
      this.classList.remove('play');
      this.classList.add('pause');
      timer(timeLeft);
      isPaused = isPaused ? false : true
    }else{
      this.classList.remove('pause');
      this.classList.add('play');
      clearInterval(intervalTimer);
      isPaused = isPaused ? false : true ;
    }
  }

  function displayTimeLeft (timeLeft){ //displays time on the input
    let minutes = Math.floor(timeLeft / 60);
    let seconds = timeLeft % 60;
    let displayString = `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    displayOutput.textContent = displayString;
    update(timeLeft, wholeTime);
  }

  return {
    start : function(){
      //console.log("in startTimer");
      if(isStarted === false){
        timer(wholeTime);
        isStarted = true;
      }else if(isPaused){
        timer(timeLeft);
        isPaused = isPaused ? false : true;
      }else{
        clearInterval(intervalTimer);
        isPaused = isPaused ? false : true ;
      }
      return this;
    }

  };
})();


//pauseBtn.addEventListener('click',pauseTimer);
