let serviceEnv;
const host = window.location.hostname;

// env based set credentials of the application
if (host.includes('expert-on-demand-int.webex.com') || host.includes('expert-on-demand-loadtest.webex.com')) {
  serviceEnv = 'integration';
} else if (host.includes('expert-on-demand.webex.com')) {
  serviceEnv = 'production';
} else {
  serviceEnv = process.env.SERVICE_ENV || 'production';
}

export const config = {
    credentials: {
      clientType: 'confidential',
      client_id: serviceEnv === 'production' ? process.env.PROD_CLIENT_ID : process.env.INT_CLIENT_ID,
      redirect_uri: serviceEnv === 'production' ? process.env.PROD_REDIRECT_URI : process.env.INT_REDIRECT_URI,
    },
};